const randomHex = require('random-hex-color')
const chroma = require('chroma-js')
const sample = require('lodash.sample')
const adaptiveGradient = require('adaptive-gradient').default
const { randomInt } = require('../util')
const randomBoxShadow = require('./box-shadow')
const { Delaunay } = require('d3-delaunay')
const seedrandom = require('seedrandom')

const generateDiv = color => {
  color = color || randomHex()
  let parentBg = randomHex()

  const pointsCount = randomInt(100, 160)
  const seed = sample(['skullcat', 'rad', 'sparkle', 'fun', 'alpine'])
  const height = 32
  const width = 32
  const viewBoxHeight = 32
  const viewBoxWidth = 32
  const strokeWidth = randomInt(1, 4)

  const random = seedrandom(seed)
  const coordinates = [[0, 0], [0, 0], [32, 32], [0, 32], [32, 0]]
  const logoOuterCoordinates = [[0, 0], [32, 32], [0, 32], [32, 0]]

  for (let i = 0; i < pointsCount; ++i) {
    let randomPoint = [
      randomInt(randomInt(0, viewBoxWidth), viewBoxWidth * random()),
      randomInt(randomInt(0, viewBoxHeight), viewBoxHeight * random())
    ]
    coordinates.push(randomPoint)
  }

  const pointsInt = randomInt(0, 100)

  let pointsRangeMax = 8
  if (pointsInt > 2) {
    pointsRangeMax = randomInt(4, 12)
  } else if (pointsInt > 3) {
    pointsRangeMax = randomInt(5, 8)
  } else {
    pointsRangeMax = randomInt(2, 16)
  }

  const div = {
    pointsCount: pointsCount,
    pointsRangeMax: pointsRangeMax,
    coordinates: coordinates,
    color: color,
    parentBg: parentBg,
    viewBoxWidth: viewBoxWidth,
    viewBoxHeight: viewBoxHeight,
    strokeWidth: 1,
    borderWidth: randomInt(0, 8),
    pointStrokeWidth: sample([
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      1,
      1,
      1,
      1,
      1,
      2,
      3,
      4
    ]),
    mixBlendMode: 'none',
    width: width,
    height: height,
    xValue: 0,
    yValue: 0,
    dashArray1: 0,
    dashArray2: 0,
    dashArray3: 0,
    dashArray4: 0,
    strokeOpacity: 100,
    logoOuterCoordinates: logoOuterCoordinates
  }

  return {
    ...div
  }
}

module.exports = generateDiv
